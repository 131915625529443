body {
  margin: 0;
  font-family: 'Mulish';
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  height: 100vh;
}

@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  src: url('../src/assets/fonts/mulish-v13-cyrillic_latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/mulish-v12-latin_cyrillic-regular.woff2') format('woff2'),
    url('../src/assets/fonts/mulish-v12-latin_cyrillic-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: url('../src/assets/fonts/mulish-v12-latin_cyrillic-600.woff2') format('woff2'),
    url('../src/assets/fonts/mulish-v12-latin_cyrillic-600.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('../src/assets/fonts/mulish-v13-cyrillic_latin-700.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  src: url('../src/assets/fonts/mulish-v13-cyrillic_latin-800.woff2') format('woff2');
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
#root {
  width: 100%;
  height: 100%;
}
