.containerClass_standard {
  background-color: white !important;
  border: 1px solid white !important;
}

.containerClass_standard_border {
  border: 1px solid #9c9c9c !important;
}

.containerClass_standard label {
  display: none;
}

.containerClass_standard_error {
  border: 1px solid red !important;
}

.buttonClass_standard {
  background-color: white !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
}

.buttonClass_standard div:hover,
.buttonClass_standard div:focus {
  background-color: transparent !important;
}

.buttonClass_custom {
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
}

.buttonClass_custom div:hover,
.buttonClass_custom div:focus {
  background-color: transparent !important;
}
.buttonClass_standard_border {
  border-radius: 5px !important;
}

.dropdownClass_standard {
  background-color: white !important;
  border-radius: 5px !important;
}

.searchClass_standard {
  width: 100% !important;
  padding: 10px !important;
}

.containerClass_underline {
  background-color: transparent !important;
  border: none;
}

.containerClass_standard_error {
  border: 1px solid red !important;
}

/* Input underline */

.buttonClass_underline {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
}

.buttonClass_underline {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
}

.dropdownClass_underline li {
  background-color: #232323 !important;
  border-radius: 0 !important;
  color: white;
}

.dropdownClass_underline li:hover {
  background-color: #232323 !important;
  border-radius: 0 !important;
  color: #232323;
}

.dropdownClass_underline .highlight {
  background-color: #232323 !important;
  border-radius: 5px !important;
  color: #232323 !important;
}

.inputClass_underline {
}
.inputClass_standard {
  border: 0 !important;
}
.searchClass_underline {
  width: 100% !important;
  padding: 10px !important;
  background-color: #232323 !important;
  color: white !important;
}
